import Middleware from './middleware'
import Session from '@/controllers/session-controller'

class CommonAccess extends Middleware {
  handle(to, from, next) {
    if (Session.isAdmin() || Session.isUser() || Session.isTeam()) {
      next();
      return;
    }

    // alert('Only Authenticated User Can Access!');
    next({ name: 'teamlogin' });
  }
}

export default CommonAccess;
