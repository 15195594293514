export default class StringUtils {
    static isEmpty(value) {
      if (value == undefined || value == null || value == 'null' || value == '') {
        return true;
      }
  
      return false;
    }
    static convertBom(data) {
      if (data.codePointAt(0) == 0xfeff) return data;
      else return '\uFEFF' + data;
    }
  }
  