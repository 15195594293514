import Middleware from './middleware'
import Session from '@/controllers/session-controller'

class ModuleAccess extends Middleware {
  handle(to, from, next) {
    if (Session.isAuth() && Session.isAdmin()) {
      next();
      return
    }

    if (Session.isAuth() && Session.isOrganisation()) {

      if (Session.checkModule(to.name)) {
        next();
        return;
      }

    }

    alert('Permission Denied!');
    next({ name: 'home' });
  }
}

export default ModuleAccess;
