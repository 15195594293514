import StringUtils from '@/utils/string-utils';
import Cookies from 'js-cookie';

export default class Cookie {
  static deleteUser() {
    this.deleteCookie('user');
  }

  static deleteJwt() {
    this.deleteCookie('jwt');
  }

  // read jwt in cookie
  static get jwt() {
    return this.getCookie('jwt');
  }

  // write jwt in cookie
  static set jwt(newJwt) {
    this.setCookie('jwt', newJwt);
  }

  // read user in cookie
  static get User() {
    const userString = this.getCookie('user') || '';
    if (StringUtils.isEmpty(userString)) return null;
    const user = JSON.parse(userString);
    return user;
  }

  // write user in cookie
  static set User(newUser) {
    this.setCookie('user', JSON.stringify(newUser), this.remember ? 10 : 0);
  }

  /**
   * write cookie
   * @param cname Cookie Name
   * @param cvalue Cookie
   * @param exdays life date
   */
  static setCookie(cname, cvalue, exdays = 0, secure = false) {
    Cookies.set(cname, cvalue, { expires: exdays == 0 ? undefined : exdays, secure: secure });
  }

  /**
   * read cookie
   * @param cname Cookie Name
   * @returns cname
   */
  static getCookie(cname) {
    return Cookies.get(cname);
  }

  /**
   * delete cookie
   * @param cname Cookie name to be deleted
   */
  static deleteCookie(cname) {
    Cookies.remove(cname);
  }

  /**
   * write storage
   * @param cname Cookie Name
   * @param cvalue Cookie
   * @param exdays life date
   */
  static setStorage(cname, cvalue) {
    localStorage.setItem(cname, cvalue);
  }

  /**
   * read cookie
   * @param cname Cookie Name
   * @returns cname
   */
  static getStorage(cname) {
    return localStorage.getItem(cname);
  }

  /**
   * delete storage
   * @param cname Cookie name to be deleted
   */
  static deleteStorage(cname) {
    localStorage.removeItem(cname);
  }
}
