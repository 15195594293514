export default class Database {

    /**
     * Inserts a key-value pair into the local storage.
     * @param {string} key - The key to be used for storing the value.
     * @param {any} value - The value to be stored.
     * @returns {any} The stored value.
     */
    static insert(key, value) {
        localStorage.setItem(key, value);
        return value;
    }

    /**
     * Updates the value associated with the specified key in the local storage.
     * @param {string} key - The key whose value should be updated.
     * @param {any} value - The new value to be stored.
     * @returns {any} The updated value.
     */
    static update(key, value) {
        localStorage.setItem(key, value);
        return value;
    }

    /**
     * Deletes the key-value pair associated with the specified key from the local storage.
     * @param {string} key - The key to be deleted.
     * @returns {boolean} Returns `true` if the key was successfully deleted, `false` otherwise.
     */
    static delete(key) {
        localStorage.removeItem(key);
        return true;
    }

    /**
     * Retrieves the value associated with the specified key from the local storage.
     * @param {string} key - The key whose value should be retrieved.
     * @returns {any} The retrieved value, or `null` if the key does not exist.
     */
    static get(key) {
        const value = localStorage.getItem(key);
        return value ? value : null;
    }

    /**
     * Drops all data from the local storage.
     * @returns {void}
     */
    static drop() {
        localStorage.clear();
    }

    
}
