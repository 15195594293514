import Vue from "vue";
import Router from "vue-router";
import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import simpleLayout from "@/layouts/single-card";
import { AdministratorAccess, CommonAccess, ModuleAccess, PublicAccess } from '@/middlewares'
import SessionController from "@/controllers/session-controller";

Vue.use(Router);

var routes = [
  {
    path: '/',
    redirect: 'dashboard',
    name: 'home',
    beforeEnter: (to, from, next) => {

      if (!SessionController.isAuth()) return next({ name: '/team-login' });

      if (SessionController.isTeam()) {
        return next({ name: 'plantafel' });
      }

      next();
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      layout: defaultLayout,
      content: () => import('@/views/home-page'),
    },
    beforeEnter: (to, from, next) => {

      if (!SessionController.isAuth()) return next({ name: '/team-login' });

      if (SessionController.isTeam()) {
        return next({ name: 'plantafel' });
      }

      next();
    }
  },
  {
    path: '/plantafel/:teamOid?',
    name: 'plantafel',
    components: {
      layout: defaultLayout,
      content: () => import('@/views/planung/plantafel'),
    }
  },
  {
    path: '/auftragplanungs',
    name: 'auftragplanungs',
    components: {
      layout: defaultLayout,
      content: () => import('@/views/auftrag/auftragplanungs'),
    }
  },
  {
    path: '/login',
    name: 'login',
    components: {
      layout: simpleLayout,
      content: () => import('@/views/auth/login'),
    },
  },
  {
    path: '/team-login',
    name: 'teamlogin',
    components: {
      layout: simpleLayout,
      content: () => import('@/views/auth/team-login'),
    },
  },
  {
    path: '/settings-auftragsplanung',
    name: 'settings-auftragsplanung',
    components: {
      layout: defaultLayout,
      content: () => import('@/views/settings/auftragsplanung'),
    }
  },
  {
    path: '/profile',
    name: 'profile',
    components: {
      layout: defaultLayout,
      content: () => import('@/views/profile-page'),
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error')
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const AdminMiddleware = new AdministratorAccess();
const CommonMiddleware = new CommonAccess();
const ModuleMiddleware = new ModuleAccess();
const PublicMiddleware = new PublicAccess();

routes = PublicMiddleware.setPaths(routes, ['login', 'teamlogin', 'error-404']);

routes = AdminMiddleware.setPaths(routes, ['settings-auftragsplanung']);

routes = CommonMiddleware.setPaths(routes, ['plantafel']);

routes = ModuleMiddleware.setPaths(routes, []);

export default new Router({
  routes,
  mode: "history",
});
