import network from "@/constants/network";
import { Axios } from "axios"
import Cookie from "./cookie-controller";
import Database from "@/database/database";
import UI from "./ui-controller";

export default class Api {
    static axios = new Axios();

    static isOnline() {
        return navigator.onLine
    }

    static async setup() {
        if (this.isBrowser()) {
            this.axios = new Axios({
                baseURL: network.api,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            if (Cookie.jwt) {
                this.setToken(Cookie.jwt);
            }
        }
    }

    static async request(method, url, requestConfig, fromCache = false) {
        try {

            if (Cookie.jwt) {
                // this.axios.defaults.headers.Authorization = 'Bearer ' + Cookie.jwt;
                let tokenQueryParam = "token=" + 'Bearer ' + Cookie.jwt;

                if (!url.includes('token=Bearer')) {

                    if (url.includes("?")) {
                        // URL'de zaten bir query parametresi varsa
                        url += "&" + tokenQueryParam;
                    } else {
                        // URL'de hiçbir query parametresi yoksa
                        url += "?" + tokenQueryParam;
                    }
                }

            }

            var response = await this.axios.request(Object.assign({ method, url: network.api + url }, requestConfig));

            // Cache Layer

            if (method == "GET" && Api.isOnline() && response.status >= 200 && response.status < 299) {
                var key = decodeURIComponent(url);
                Database.insert(key, response.data);
            }

            if (response.status >= 200 && response.status < 299)
                return JSON.parse(response.data);
            else
                throw JSON.parse(response.data)

        } catch (error) {
            if (method == "GET" && !Api.isOnline() || fromCache) {
                var _key = decodeURIComponent(url);
                var data = Database.get(_key);

                if (data != null) {
                    console.log('Response from cache ' + method + ' ' + url);
                    return JSON.parse(data);
                }
                else
                    throw new Error("Connection failed");
            }


            if ((method == "POST" || method == "PUT") && !Api.isOnline()) {
                // addEventListener version

                window.removeEventListener("online", Api.refreshApp);

                window.addEventListener("online", Api.refreshApp);

                var rawRequestList = Database.get('Request') || "[]";
                var requestList = JSON.parse(rawRequestList);

                requestList.push({
                    method,
                    url,
                    requestConfig
                });

                var requestData = JSON.stringify(requestList);

                Database.insert('Request', requestData);

                UI.showToast('offline, Daten wurden zwischengespeichert');
                return null;
            }

            throw error;
        }
    }

    static refreshApp() {
        location.reload();
    }

    static async applyCacheRequest() {
        console.log("You are now connected to the network.");

        var rawRequestList = Database.get('Request') || "[]";
        var requestList = JSON.parse(rawRequestList);

        var requestPromise = requestList.map(async (request, i) => {

            console.log("Request " + i);

            return new Promise((resolve) => {
                Api.request(request.method, request.url, request.requestConfig).then((response) => {
                    // this deley for object locking
                    setTimeout(() => {
                        resolve(response);
                    }, 500);
                });
            });

        });

        await Promise.all(requestPromise);

        // Database.delete('Request');

        Database.drop();

        return requestList.length;
    }

    /**
     * Set token on Axios configuration
     * @param token Retrieved by register or login
     */
    static setToken(token) {
        this.axios.defaults.headers.Authorization = 'Bearer ' + token;
        if (this.isBrowser()) {
            Cookie.jwt = token;
        }
    }

    /**
     * Check if it runs on browser
     */
    static isBrowser() {
        return typeof window !== 'undefined';
    }
}