
export default class BaseController {
    static privates = new Map()

    static get loading() {
        var _loading = this.privates.get("loading");
        return _loading ?? false;
    }

    static set loading(val) {
        this.privates.set("loading", val);

        var countdown = this.privates.get("countdown");
        if (this.loading == true) {
            countdown = setTimeout(() => {
                if (this.loading) {
                    this.loading = false;
                    console.warn('long process');
                    this.privates.set("countdown", null);
                }
            }, 25000);
            this.privates.set("countdown", countdown);
        }
        else if (countdown != null) {
            clearTimeout(countdown);
            this.privates.set("countdown", null);
        }
    }
}