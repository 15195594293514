import Cookie from "@/controllers/cookie-controller"
import Api from "./api-controller";
import BaseController from "./base-controller";
import Database from "@/database/database";

export default class SessionController extends BaseController {
    static isAuth() {
        if (Cookie.jwt == null)
            return false;
        else
            return true;
    }

    static isAdmin() {
        if (!this.isAuth())
            return false;
        return Cookie.User.role == "Administrator";
    }

    static isTeam() {
        if (!this.isAuth())
            return false;
        return Cookie.User.role == "Team";
    }

    static isUser() {
        if (!this.isAuth())
            return false;
        return false;
    }

    static getUser() {
        return Cookie.User;
    }

    static async login(username, password, mandant) {
        this.loading = true

        var response = await Api.request("POST", "/api/Auth/login", {
            data: JSON.stringify({
                username,
                password,
                mandant
            })
        });

        Cookie.jwt = response.data.jwt;
        Cookie.User = response.data.user;

        this.loading = false
    }

    static async loginWithTeam(team, password) {
        this.loading = true

        var response = await Api.request("POST", "/api/Auth/TeamLogin", {
            data: JSON.stringify({
                team, password
            })
        });

        Cookie.jwt = response.data.jwt;
        Cookie.User = response.data.user;

        this.loading = false
    }

    static logout() {
        Cookie.deleteUser();
        Cookie.deleteJwt();
        Database.drop();
    }
}