import _ from 'lodash';


class Middleware {
  handle(to, from, next) {
    next();
  }
  setPaths(routes, paths) {
    var newRoutes = _.map(routes, x => {

      if (!x) return x;

      var isAllow = _.includes(paths, x.name);
      if (isAllow) {
        x.beforeEnter = this.handle
      }

      return x;
    });

    return newRoutes;
  }
}

export default Middleware;
