import Middleware from './middleware'
import Session from '@/controllers/session-controller'

class AdministratorAccess extends Middleware {
  handle(to, from, next) {
    if (Session.isAdmin()) {
      next();
      return;
    }
    
    // alert('Only Admin Can Access!');
    next({ name: 'teamlogin' });
  }
}

export default AdministratorAccess;
