import Middleware from './middleware'
import Session from '@/controllers/session-controller'

class PublicAccess extends Middleware {
  handle(to, from, next) {
    if (!Session.isAuth()) {
      next();
      return;
    }
    next({ name: 'home' });
  }
}

export default PublicAccess;
