import notify from "devextreme/ui/notify";


export default class UI {

    static showToast(message, type = 'success', displayTime = 3500) {
        notify(
            {
                message: message,
                height: 45,
                minWidth: 150,
                type: type,
                displayTime: displayTime,
                animation: {
                    show: {
                        type: "fade",
                        duration: 400,
                        from: 0,
                        to: 1,
                    },
                    hide: { type: "fade", duration: 40, to: 0 },
                },
            },
            {
                position: "top center",
                direction: "down-push",
            }
        );
    }
}