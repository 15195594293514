import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './themes/app.css';
import Vue from "vue";
import App from "./App";
import router from "./routers/router";
import appInfo from "@/constants/app-info";
import Api from './controllers/api-controller';
import UI from './controllers/ui-controller';
import './registerServiceWorker'
import Database from './database/database';

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.use
Api.setup();

var rawRequestList = Database.get('Request') || "[]";
var requestList = JSON.parse(rawRequestList);

if (requestList.length > 0) {
  setTimeout(async () => {
    var requestListLength = await Api.applyCacheRequest();

    UI.showToast(`Syncronized Cache ${requestListLength} Request`);

    setTimeout(() => {
      location.reload();
    }, 1000)

  }, 3000);
}

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

